<script>
export default {
    name: "avatar",

    props: {
        user: {
            type: Object,
            required: true
        },

        hover: {
            type: Boolean,
            default: false
        },

        variant: {
            type: String,
            default: 'primary'
        },

        size: {
            type: String,
            default: 'sm'
        },

        thumbnail: {
            type: Boolean,
            default: false
        },

        link: {
            type: Boolean,
            default: true
        }
    },

    data: function () {
        return {
            showHover: false
        };
    },
}
</script>

<template>
    <component :is="link ? 'router-link' : 'div'" :to="$t('routes./profiles/:id(\\d+)', {id: user?.id}).rmValidation()" class="avatar-item">
        <span v-if="user?.image" class="d-inline-block position-relative">
            <img :src="user.image"
                 :alt="user?.name"
                 :title="user?.name"
                 class="rounded-circle avatar-image" :class="'avatar-' + size + (thumbnail ? ' img-thumbnail' : '')">
            <slot name="hover">

            </slot>
        </span>
        <div v-else class="cursor-pointer d-inline-block" :class="'avatar-' + size">
            <span :title="user?.name" class="avatar-title rounded-circle text-white user-select-none" :class="{'font-size-16': size !== 'xs', 'font-size-10': size === 'xs'}" :style="user?.color ? {background: user.color} : {}"> {{ user?.name?.firstLetters() }} </span>
            <slot name="hover">

            </slot>
        </div>
    </component>
</template>

<style>
    .avatar-item:hover {
        .avatar-hover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.85;
            display: flex !important;
        }
    }

    .avatar-24px {
        height: 24px;
        width: 24px;
    }
</style>
