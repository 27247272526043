<script>
/*import UserSelector from "./user-selector.vue";*/
import MemberCard from "./member-card.vue";
import PersonSelector from "./person-selector.vue";

export default {
    name: "member-manager",

    components: {PersonSelector, MemberCard, /*UserSelector*/},

    props: {
        store: {
            type: String,
            required: true
        },

        id: {
            type: [String, Number],
            required: true
        }
    },

    computed: {
        allMember: function () {
            return this.$store.getters[this.store + '/allMember'];
        },

        leader: function () {
            return this.$store.getters[this.store + '/leader'];
        },

        members: function () {
            return this.$store.getters[this.store + '/members'];
        },

        pivots: function () {
            return this.$store.getters[this.store + '/pivots'];
        },

        leaderLoading: function () {
            return this.$store.getters[this.store + '/leaderLoading'];
        },

        memberLoading: function () {
            return this.$store.getters[this.store + '/memberLoading'];
        },

        excludes: function () {
            return this.allMember?.map(m => m.id) || [];
        },

        multiTypeExcludes: function () {
            return this.allMember?.map(m => {
                return {type: m.type, id: m.id};
            }) || [];
        },
    },

    methods: {
        fetchMembers: function () {
            this.$store.dispatch(this.store + '/members', this.id)
        },

        sync: function (pivots) {
            this.$store.dispatch(this.store + '/sync', {id: this.id, pivots: pivots})
        },

        updateLeader: function (id) {
            this.$store.dispatch(this.store + '/updateLeader', {id: this.id, leader_id: id})
        },

        addMembers: function (users) {
            const pivots = users.map(u => u.id).concat(this.pivots);
            this.sync(pivots)
        },

        addMembersWithMultipleType: function (users) {
            const pivots = users.concat(this.pivots);
            this.sync(pivots)
        },

        remove: function (user) {
            const index = this.pivots.findIndex(piv => piv.id === user.id && piv.type === user.type);

            const pivots = JSON.parse(JSON.stringify(this.pivots));

            pivots.splice(index, 1);

            this.sync(pivots);
        },
    },

    watch: {
        id: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.fetchMembers();
                }
            },
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-9">
            <b-overlay :show="memberLoading">
                <div class="row">
                    <div v-if="leader?.id" class="col-lg-4 col-sm-6">
                        <member-card :user="leader" @leader="updateLeader" @remove="remove"></member-card>
                    </div>
                    <div v-for="(member) in members" :key="member.id" class="col-xl-4 col-sm-6">
                        <member-card :user="member" @leader="updateLeader" @remove="remove"></member-card>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div class="col-lg-3">
            <person-selector :excludes="multiTypeExcludes" @select="addMembersWithMultipleType"></person-selector>
        </div>
    </div>
</template>

<style scoped>

</style>
