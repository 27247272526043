<script>
import Avatar from "./user/avatar.vue";

export default {
    name: "member-card",

    components: {Avatar},

    emits: ['leader', 'remove'],

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        canBeLeader: function () {
            return this.user.type === 'user';
        },

        contributor: function () {
            return this.user.type === 'contributor';
        },

        isLeader: function () {
            return this.user.is_leader === true;
        },
    },

    methods: {
        leader: function () {
            this.$emit('leader', this.user.id)
        },

        remove: function () {
            this.$emit('remove', this.user)
        },
    }

}
</script>

<template>
    <div class="card" :class="{'border border-dark bg-soft-dark bg-gradient': isLeader, 'border border-warning bg-gradient': contributor}">
        <div class="card-body">
            <div class="dropdown float-end">
                <a
                    aria-haspopup="true"
                    class="dropdown-toggle font-size-16"
                    :class="{'text-muted': !isLeader, 'text-white': isLeader}"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                >
                    <i class="bx bx-dots-horizontal-rounded"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" v-if="canBeLeader && !isLeader" href="#" @click="leader">{{ $t('organizations::divisions.be_the_leader').ucFirst() }}</a>
                    <a class="dropdown-item" href="#" @click="remove">{{ $t('base.remove').ucFirst() }}</a>
                </div>
            </div>
            <div v-if="isLeader" class="dropdown float-end me-2" :title="$t('organizations::teams.relationships.leader').ucFirst()">
                <i class="text-light mdi mdi-account-tie-voice"></i>
            </div>
            <div v-if="contributor" class="dropdown float-end me-2" :title="$tc('organizations::contributors.contributor', 1).ucFirst()">
                <i class="text-dark mdi mdi-badge-account-alert-outline"></i>
            </div>
            <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                    <avatar :user="user" size="md" thumbnail></avatar>
                </div>
                <div class="flex-1 ms-3">
                    <h5 class="font-size-16 mb-1">
                        <a :class="{'text-dark': !isLeader, 'text-white': isLeader}" href="#">{{ user?.name }}</a>
                    </h5>
                    <span class="badge mb-0"
                          :class="{'badge-soft-success': !isLeader, 'bg-success text-soft-success': isLeader}"
                    >{{ user?.position?.translation_key ? $t(user.position.translation_key).ucFirst() : user?.position?.name }}</span
                    >
                </div>
            </div>
            <div class="mt-3 pt-1">
                <p v-if="user?.phone" class="mb-0" :class="{'text-muted': !isLeader, 'text-light': isLeader}">
                    <i
                        class="
                            mdi mdi-phone
                            font-size-15
                            align-middle
                            pe-2
                          "
                        :class="{'text-primary': !isLeader, 'text-white': isLeader}"
                    ></i>
                    {{ user.phone }}
                </p>
                <p v-if="user.email" class="mb-0 mt-2" :class="{'text-muted': !isLeader, 'text-light': isLeader}">
                    <i
                        class="
                            mdi mdi-email
                            font-size-15
                            align-middle
                            pe-2
                          "
                        :class="{'text-primary': !isLeader, 'text-white': isLeader}"
                    ></i>
                    {{ user?.email }}
                </p>
            </div>
        </div>
    </div>
    <!-- end card -->
</template>

<style scoped>

</style>
