<script>
import {mapGetters} from "vuex";
import _ from "lodash"
import Avatar from "./user/avatar.vue";
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "person-selector",

    components: {TransitionToggle, Avatar},

    emits: ['select'],

    props: {
        excludes: {
            type: Array,
            default: () => { return []; }
        },

        limit: {
            type: Number,
            default: 10
        }
    },

    computed: {
        ...mapGetters({
            loading: 'people/loadingSearch',
            users: 'people/searchList',
            noMore: 'people/searchEnd'
        })
    },

    data: function () {
        return {
            temp: '',
            search: '',
            prev: '',
            selected: []
        }
    },

    methods: {
        init: function () {
            if (!this.users?.length) {
                this.fetch(null)
            }
        },

        fetch: function (search) {
            this.$store.dispatch('people/search', {search: search, per_page: this.limit}).then(() => {
                this.prev = search;
            });
        },

        more: function () {
            this.$store.dispatch('people/moreSearch')
        },

        clear: function () {
            this.selected = [];
            this.$store.dispatch('people/clearSearch');
        },

        select: function (item) {
            this.selected.push(item);
        },

        remove: function (index) {
            this.selected.splice(index, 1);
        },

        isSelected: function (id, type) {
            console.log(id, type, this.excludes)
            return this.selected.findIndex(s => s.id === id && s.type === type) > -1 || this.excludes.find(exc => exc.id === id && exc.type === type);
        },

        emitSelect: function () {
            this.$emit('select', this.selected);
            this.clear()
        },
    },

    watch: {
        search: {
            handler: function (value) {
                this.temp = value;
                _.debounce( (value) => {
                    if (this.temp === value) {
                        this.search = value;
                        this.fetch(value);
                    }
                }, 700)(value);
            },
        }
    }
}
</script>

<template>
    <div class="user-selector bg-white pt-4 p-3 rounded">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="row">
                    <div class="col-lg-2">
                        <b-button :title="$t('base.add').ucFirst()" class="w-100" variant="outline-light" @click="emitSelect">
                            <i class="mdi mdi-chevron-left"></i>
                        </b-button>
                    </div>
                    <div class="col-lg-10">
                        <div class="avatar-group">
                            <avatar @click="remove(index)" :link="false" class="avatar-group-item" v-for="(item, index) in selected" :key="item.id" :user="item"></avatar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4">
                <div class="search-box bg-white">
                    <div class="position-relative">
                        <input v-model="search" @focus="init" type="text" class="form-control bg-light border-0" :placeholder="$t('base.search').ucFirst() + ' ...'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" class="eva eva-search-outline search-icon"><g data-name="Layer 2"><g data-name="search"><rect width="24" height="24" opacity="0"></rect><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <transition-toggle>
                    <div v-if="users?.length" class="max-h-32 overflow-y-scroll">
                        <template v-for="(user, index) in users" :key="index">
                            <div v-if="!isSelected(user.id, user.type)" class="d-flex align-items-center w-100 mb-2 bg-light-hover cursor-pointer" @click="select(user)">
                                <div class="me-3">
                                    <avatar :user="user" size="md" thumbnail></avatar>
                                </div>
                                <div class="flex-grow-1 border-bottom py-3">
                                    <span class="d-block">{{ user.name }}</span>
                                    <span v-if="user?.position" class="badge mb-0" :class="{'bg-soft-primary text-primary': !user.position.color}" :style="{backgroundColor: user.position.color}">
                                        <i v-if="user.position.icon" class="me-1 font-size-9" :class="user.position.icon"></i>
                                        {{ user?.position?.translation_key ? $t(user?.position?.translation_key).ucFirst() : user?.position?.name }}
                                    </span>
                                    <span v-if="user?.specialization" class="badge ms-1 mb-0" :class="{'bg-soft-primary text-primary': !user.specialization.color}" :style="{backgroundColor: user.specialization.color}">
                                        <i v-if="user.specialization.icon" class="me-1 font-size-9" :class="user.specialization.icon"></i>
                                        {{ user?.specialization?.translation_key ? $t(user?.specialization?.translation_key).ucFirst() : user?.specialization?.name }}
                                    </span>
                                    <span v-if="user?.knowledge_level" class="badge ms-1 mb-0" :class="{'bg-soft-primary text-primary': !user.knowledge_level.color}" :style="{backgroundColor: user.knowledge_level.color}">
                                        <i v-if="user.knowledge_level.icon" class="me-1 font-size-9" :class="user.knowledge_level.icon"></i>
                                        {{ user?.knowledge_level?.translation_key ? $t(user?.knowledge_level?.translation_key).ucFirst() : user?.knowledge_level?.name }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </transition-toggle>
                <div class="">
                    <transition-toggle>
                        <b-button variant="outline-light" class="w-100 mt-2" v-if="!noMore && users?.length" @click="more" :disabled="loading">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>{{ $t('base.more').ucFirst() }}</span>
                        </b-button>
                    </transition-toggle>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
